import { Float } from "@react-three/drei"
import { useEffect, useRef, useState, forwardRef } from "react"
import { LoadingManager } from "three"
import { GLTFLoader } from "three/examples/jsm/Addons.js"
import gsap from "gsap"
import { Preloader } from "./Preloader"
import { DRACOLoader } from "three/examples/jsm/Addons.js"
import * as THREE from 'three'
import { useThree } from '@react-three/fiber'

export const Plate = forwardRef((props, ref) => {

    const [isPreloader, setIsPreloader] = useState(true)
    const preloader = useRef()
    const { camera } = useThree()
    const [plate, setPlate] = useState(false)

    // Plate loading
    useEffect(() => {

        const loadingManager = new LoadingManager(
            // On load
            () => {
                gsap.to(preloader.current, {
                    duration: 1,
                    opacity: 0,
                    ease: 'power1.out',
                    onComplete: () => {
                        setIsPreloader(false)
                    }
                })
            },
            // On progress
            (itemURL, itemsLoaded, itemsTotal) => {
                const loadingPercent = preloader.current.querySelector('#loading-percent')
                const progressRatio = itemsLoaded / itemsTotal
                loadingPercent.innerText = `${(progressRatio * 100).toFixed(0)}%`
            }
        )

        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('./draco/')

        const gltfLoader = new GLTFLoader(loadingManager)
        gltfLoader.setDRACOLoader(dracoLoader)

        gltfLoader.load(
            './models/rolls.glb',
            plate => {
                plate.scene.children[1].material.map.anisotropy = 8
                setPlate(plate)
            }
        )
    }, [])

    useEffect(() => {
        // Fix the touch problem on touch devices
        const isTouchDevice = window.matchMedia('(any-pointer: coarse)').matches

        if (isTouchDevice) {
            const raycaster = new THREE.Raycaster()
            const touch = new THREE.Vector2()

            function onTouchStart(event) {
                const rect = mainCanvas.getBoundingClientRect()
                touch.x = (event.touches[0].clientX / window.innerWidth) * 2 - 1
                touch.y = -((event.touches[0].clientY - rect.top) / window.innerHeight) * 2 + 1
            }

            const mainCanvas = document.querySelector('.main-canvas')

            mainCanvas.addEventListener('touchstart', (event) => {

                if (
                    event.target.className === 'product-info' ||
                    event.target.className === 'header' ||
                    event.target.tagName === 'svg' ||
                    event.target.tagName === 'IMG' ||
                    event.target.tagName === 'path' ||
                    event.target.tagName === 'LI' ||
                    event.target.tagName === 'A'
                ) { return }

                let intersectedObject = null

                onTouchStart(event)
                raycaster.setFromCamera(touch, camera)
                const intersects = raycaster.intersectObjects(ref.current.children)

                intersectedObject = intersects[0]
                
                if (intersectedObject) {
                    event.preventDefault()
                }
            }, { passive: false })
        }
    }, [])

    return (
        <>
            {
            plate
            && 
            <Float
                rotationIntensity={0}
                speed={7}
                floatingRange={[0, 0.09]}
            >
                <primitive
                    ref={ref} 
                    object={plate.scene}
                />
            </Float>
            }
            {
            isPreloader
            &&
            <Preloader ref={preloader} />
            }
        </>
    )
})