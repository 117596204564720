import gsap from "gsap"
import { useRef, useLayoutEffect } from "react"

export default function PhoneRinging() {
    const icon = useRef()

    useLayoutEffect(() => {
        setInterval(() => {
            gsap.to(icon.current, {
                rotation: 15,
                duration: .2,
                repeat: 5,
                yoyo: true,
                ease: 'power1.inOut',
            })
        }, 3000)
    }, [])

    return (
        <svg ref={icon} viewBox="0 0 508 512.06">
            <g>
                <path d="M123.43 249.39c15.07,27.16 32.42,53.26 54.98,77.05 22.61,23.95 50.71,45.72 87.14,64.3 2.7,1.32 5.27,1.32 7.55,0.42 3.46,-1.33 6.99,-4.18 10.47,-7.67 2.7,-2.69 6.04,-6.99 9.56,-11.7 13.99,-18.44 31.34,-41.29 55.81,-29.86 0.54,0.25 0.95,0.53 1.49,0.78l81.66 46.96c0.22,0.12 0.53,0.42 0.77,0.53 10.78,7.41 15.2,18.84 15.32,31.76 0,13.18 -4.85,27.98 -11.96,40.5 -9.4,16.5 -23.27,27.43 -39.25,34.69 -15.2,7 -32.13,10.75 -48.4,13.15 -25.55,3.77 -49.47,1.37 -73.94,-6.16 -23.94,-7.42 -48.03,-19.63 -74.37,-35.89l-1.95 -1.25c-12.07,-7.52 -25.12,-15.61 -37.91,-25.13 -46.91,-35.4 -94.65,-86.48 -125.74,-142.71 -26.08,-47.2 -40.32,-98.15 -32.53,-146.73 4.3,-26.63 15.72,-50.84 35.63,-66.82 17.35,-13.98 40.74,-21.64 71.01,-18.96 3.48,0.23 6.58,2.27 8.19,5.24l52.33 88.5c7.67,9.93 8.61,19.75 4.44,29.58 -3.49,8.09 -10.47,15.5 -20.04,22.46 -2.82,2.4 -6.18,4.84 -9.69,7.4 -11.71,8.5 -25,18.3 -20.45,29.85l-0.12 -0.29zm97.51 -42.25c-8.08,-2.08 -12.94,-10.35 -10.85,-18.43 2.09,-8.08 10.35,-12.94 18.43,-10.85 24.51,6.39 47.56,19.66 66.21,37.07 18.76,17.52 33.31,39.41 40.66,62.88 2.48,7.99 -1.98,16.49 -9.96,18.97 -7.98,2.49 -16.49,-1.97 -18.97,-9.95 -5.75,-18.37 -17.33,-35.69 -32.36,-49.73 -15.15,-14.15 -33.68,-24.88 -53.16,-29.96zm2.01 -92.74c-8.25,-1.4 -13.79,-9.24 -12.39,-17.49 1.39,-8.24 9.24,-13.79 17.48,-12.39 46.21,8 90.08,31.46 125.25,64.38 35.19,32.92 61.82,75.41 73.53,121.46 2.06,8.12 -2.85,16.38 -10.97,18.44 -8.12,2.06 -16.38,-2.85 -18.44,-10.97 -10.3,-40.48 -33.75,-77.89 -64.75,-106.89 -30.93,-28.95 -69.38,-49.55 -109.71,-56.54zm9.74 -84.19c-8.31,-0.95 -14.28,-8.47 -13.33,-16.78 0.95,-8.31 8.46,-14.29 16.77,-13.34 64.65,7.57 125.4,37.22 173.49,80.81 48.21,43.69 83.83,101.51 98.02,165.22 1.8,8.14 -3.35,16.22 -11.5,18.02 -8.14,1.8 -16.22,-3.35 -18.02,-11.5 -12.8,-57.45 -45.05,-109.73 -88.77,-149.34 -43.53,-39.45 -98.4,-66.28 -156.66,-73.09z"/>
            </g>
        </svg>  
    )
}