import { create } from 'zustand'

export default create((set, get) => {
  const products = [
    {
      index: 0,
      name: 'Pizza',
      rotation: 0,
      price: 1000
    },
    {
      index: 1,
      name: 'Pesto Genovese',
      rotation: Math.PI / 4,
      price: 1000
    },
    {
      index: 2,
      name: 'Čokolada',
      rotation: Math.PI / 2,
      price: 1000
    },
    {
      index: 3,
      name: 'Malina - vanila',
      rotation: 3 * Math.PI / 4,
      price: 1000
    },
    {
      index: 4,
      name: 'Višnja - vanila',
      rotation: Math.PI,
      price: 1000
    },
    {
      index: 5,
      name: 'Prazne',
      rotation: 5 * Math.PI / 4,
      price: 800
    },
    {
      index: 6,
      name: 'Sir',
      rotation: 3 * Math.PI / 2,
      price: 1000
    },
    {
      index: 7,
      name: 'Gorgonzola',
      rotation: 7 * Math.PI / 4,
      price: 1000
    }
  ]

  return {
    products,
    activeProduct: products[0],
    setActiveProduct: (product) => {
        set(
          () => {
            return { activeProduct: product }
          }
        )
    },
    getActiveProduct: () => get().activeProduct
  }
})
