import { Html } from "@react-three/drei";
import { useState, useLayoutEffect } from "react"
import gsap from "gsap";

export default function Header({ rotatePlate, products }) {

    const [menuOpen, setMenuOpen] = useState('')

    const openMenu = () => {
        setMenuOpen(menuOpen ? '' : 'open')
    }

    const rotatePlateFromMenu = (product) => {
        rotatePlate('menu', product)
        setMenuOpen(menuOpen ? '' : 'open')
    }

    useLayoutEffect(() => {
        setTimeout(() => {
            if (menuOpen) {
                gsap.to('.menu ul li', {
                    opacity: 1,
                    x: 0,
                    stagger: 0.1
                })
            }
            else {
                gsap.to('.menu ul li', {
                    opacity: 0,
                    duration: 0.3,
                    x: -30
                })
            }
        }, 700) // .7s is the animation duration for the menu to fade in, in the layout/header.scss file on line 79.
    }, [menuOpen])

    return (
        <>
            <Html className={`menu ${menuOpen}`}>
                <ul>
                    {products.map(product => <li key={product.index} onClick={() => rotatePlateFromMenu(product)}>{product.name}</li>)}
                </ul>
            </Html>
            <Html className="header">
                <img className="header__logo" src='/imgs/logo.png'/>
                <div className="header__navigation">
                    <div onClick={openMenu} className={`header__burger-menu ${menuOpen}`}>
                        <span className="header__burger-menu--top-line"></span>
                        <span className="header__burger-menu--bottom-line"></span>
                    </div>
                </div>
            </Html>
        </>
    )
}