import Instagram from "./icons/Instagram";

export default function Footer() {
    return (
        <footer className="footer">
            <div className="footer__container">
                <div className="footer__instagram-username">
                    <a href='https://www.instagram.com/medenaoklagija/'>
                        <Instagram/>
                        <span>@medenaoklagija</span>
                    </a>
                </div>
                <div className="footer__instagram-images">
                    <a href='https://www.instagram.com/medenaoklagija/'>
                        <img src='/imgs/instagram/insta1.webp'/>
                        <img src='/imgs/instagram/insta2.webp'/>
                        <img src='/imgs/instagram/insta3.webp'/>
                        <img src='/imgs/instagram/insta4.webp'/>
                        <img src='/imgs/instagram/insta5.webp'/>
                        <img src='/imgs/instagram/insta6.webp'/>
                    </a>
                </div>
                <div className="footer__copyright">
                    2024. Copyrights Medena Oklagija ©. All Rights Reserved.
                </div>
            </div>
        </footer>
    )
}