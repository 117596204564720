export default function RollingPin() {
    return (
        <svg viewBox="0 0 63.193 63.193">
            <g>
                <path d="M55.134,51.366c1.218-1.667,1.831-3.702,1.379-5.972c-0.002-0.007-0.006-0.011-0.008-0.018
                    c0.038-0.107,0.027-0.23-0.081-0.335c-13.247-13-27.729-24.836-40.553-38.227c-0.099-0.103-0.264-0.027-0.298,0.085
                    c-0.363-0.236-0.751-0.427-1.121-0.565c-0.078-0.029-0.146-0.011-0.203,0.028c-1.86,0.125-3.707,0.86-4.997,2.222
                    C7.734,6.612,7.066,3.846,4.945,2.398C3.648,1.512,1.704,1.291,0.618,2.639C-0.462,3.979,0.007,5.842,1,7.058
                    c0.813,0.995,1.923,1.624,3.026,2.248c0.788,0.446,1.518,0.914,2.208,1.434c-1.575,0.93-3.06,2.574-3.398,4.389
                    c-0.197,1.063,0.182,2.337,1.129,2.815c12.883,13.23,27.126,25.348,40.663,37.9c0.158,0.146,0.351,0.107,0.476-0.004
                    c0.01,0.002,0.014,0.011,0.025,0.012c2.363,0.191,4.943-0.499,7.067-1.816c2.26,2.19,5.86,10.784,9.929,6.099
                    C66.287,55.344,57.069,53.611,55.134,51.366z M5.228,9.282c-1.062-0.669-2.264-1.184-3.184-2.06
                    C1.668,6.863,1.262,6.435,1.027,5.969C0.363,4.652,0.585,3.894,1.713,3.038c1.132-0.86,1.42-0.86,2.609-0.194
                    c0.849,0.475,1.243,0.941,1.811,1.772c0.587,0.86,1.09,1.787,1.622,2.68c0.72,1.209,1.276,2.371,2.568,2.968
                    c0.032,0.542,0.025,1.046-0.356,1.485c-0.37,0.427-0.919,0.618-1.473,0.597c-0.01,0-0.014,0.008-0.023,0.009
                    c0.007-0.037,0.003-0.077-0.025-0.116C7.572,11.038,6.483,10.071,5.228,9.282z M6.583,11.025c0.547,0.436,1.076,0.9,1.584,1.428
                    c0.043,0.045,0.097,0.049,0.149,0.038c-0.029,0.1,0.002,0.217,0.125,0.24c1.413,0.266,2.978-1.075,2.415-2.578
                    c-0.033-0.087-0.106-0.133-0.185-0.16c-0.015-0.024-0.022-0.052-0.048-0.07C10.205,9.645,9.857,9.3,9.535,8.931
                    c1.487-1.04,2.999-1.751,4.843-1.943c0.039-0.004,0.07-0.017,0.102-0.031c3.413,1.793-0.297,5.388-2.152,6.997
                    c-2.126,1.843-4.923,4.036-7.891,3.576C1.778,15.848,4.865,12.497,6.583,11.025z M45.131,55.36
                    c-0.003-0.003-0.004-0.007-0.007-0.01C31.747,42.966,18.736,29.872,4.77,18.145c0.073-0.004,0.127-0.053,0.159-0.115
                    c3.365,0.859,7.334-2.949,9.419-5.084c0.874-0.895,2.736-2.467,2.672-3.914c-0.004-0.094-0.028-0.181-0.044-0.27
                    c4.932,6.031,11.353,10.825,17.077,16.047c7.384,6.738,14.448,13.819,21.777,20.616c-0.012,0.048-0.014,0.101-0.004,0.158
                    C56.934,51.698,50.231,55.144,45.131,55.36z M61.433,59.857c-1.358,1.388-3.416,0.462-4.59-0.696
                    c-0.727-0.718-1.278-1.566-1.799-2.437c-0.68-1.136-1.312-2.261-2.477-2.915c0.814-0.547,1.541-1.197,2.157-1.924
                    c0.808,1.004,1.855,1.572,3.048,2.058c0.86,0.35,1.754,0.635,2.554,1.115C62.019,56.075,63.102,58.151,61.433,59.857z"/>
            </g>
        </svg>      
    )
}