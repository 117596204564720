import { useThree } from "@react-three/fiber";
import { useEffect } from "react";

export default function CameraPosition() {

    const { camera } = useThree()
    
    useEffect(() => {
        if (window.innerWidth < 475) {
            camera.position.z = 2.5
        }
    }, [])

    return null
}