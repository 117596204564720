import { Html, PresentationControls, ContactShadows } from "@react-three/drei"
import { Plate } from "./Plate"
import Header from "./Header"
import { useRef, useEffect, useState } from "react"
import gsap from "gsap"
import useProducts from "./stores/useProducts"
import ProductInfo from "./ProductInfo"
import ArrowIcon from "./icons/ArrowIcon"

export default function RotatePlate() {

    const products = useProducts(state => state.products)
    const activeProduct = useProducts(state => state.getActiveProduct())
    const leftArrow = useRef()
    const rightArrow = useRef()
    const setActiveProduct = useProducts(state => state.setActiveProduct)
    const plate = useRef()
    const [lastRotationTime, setLastRotationTime] = useState(0)

    const rotatePlate = (rotateOrigin, product) => {
        const animationDuration = 400
        const currentTime = Date.now()
        if (currentTime - lastRotationTime < animationDuration) return

        const gsapRotationAnimation = (rotation) => {
            gsap.to(plate.current.rotation, { y: rotation, duration: animationDuration / 1000, ease: "power2.out" })
        }

        setLastRotationTime(currentTime)

        let newProduct
        if (rotateOrigin === 'rightArrow') {
            if (activeProduct.index >= 7) {
                newProduct = products[0]
            } else {
                newProduct = products[activeProduct.index + 1]
            }
        } 
        else if (rotateOrigin === 'leftArrow') {
            if (activeProduct.index <= 0) {
                newProduct = products[7]
            } else {
                newProduct = products[activeProduct.index - 1]
            }
        }
        else if (rotateOrigin === 'menu') {

                setTimeout(() => {
                    gsapRotationAnimation(product.rotation)
                }, 300) // Wait for the menu to close
                setActiveProduct(product)

                return
        }

        setActiveProduct(newProduct)
        const currentRotation = plate.current.rotation.y
        const additionalRotation = rotateOrigin === 'rightArrow' ? Math.PI / 4 : -Math.PI / 4
        gsapRotationAnimation(currentRotation + additionalRotation)
    }

    useEffect(() => {
        setTimeout(() => {
            gsap.to(leftArrow.current, {
                x: '10',
                ease: 'elastic.out(1, 0.3)',
                repeat: -1, // Makes the animation infinite
                yoyo: true, // Makes the animation go back and forth
                duration: 1
            })
            gsap.to(rightArrow.current, {
                x: '-10',
                ease: 'elastic.out(1, 0.3)',
                repeat: -1,
                yoyo: true,
                duration: 1
            })
        }, 500)
    }, [])

    return (
        <>
            <Header 
                rotatePlate={rotatePlate}
                products={products}
            />
            <PresentationControls
                rotation={[Math.PI / 6, Math.PI / 17, 0]} // default rotation
                azimuth={[0, 0]} // horizontal limits
                polar={[-Math.PI / 4, Math.PI / 3]} // drag limits
                cursor={false}
            >
                <Plate ref={plate} />
            </PresentationControls>
            <ContactShadows
                position-y={-1.0}
                opacity={0.4}
                scale={5}
                blur={2.4}
            />
            <Html className="arrows">
                <div ref={leftArrow} className="arrows__left" onClick={() => rotatePlate('leftArrow')}>
                    <ArrowIcon/>
                </div>
                <div ref={rightArrow} className="arrows__right" onClick={() => rotatePlate('rightArrow')}>
                    <ArrowIcon/>
                </div>
            </Html>
            <ProductInfo activeProduct={activeProduct}/>
        </>
    )
}
