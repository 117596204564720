export default function Lights() {

    return (
        <>
            <directionalLight
                position={[4, 10, 17]}
                intensity={2}
            />
            <ambientLight intensity={0.4}/>
        </>
    )
}
