import { Html } from "@react-three/drei"
import { forwardRef } from 'react'
import RollingPin from "./icons/RollingPin"

export const Preloader = forwardRef((props, ref) => {

    return (
        <Html className="preloader" >
            <div id='preloader' ref={ref}>
                <div id='svg'>
                    <RollingPin/>
                </div>
                <div id="loading-percent">0%</div>
            </div>
        </Html>
    )
})