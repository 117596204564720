import { Canvas } from "@react-three/fiber";
import Lights from "./Lights";
import RotatePlateHandler from "./RotatePlateHandler";
import Footer from "./Footer";
import FooterSectionDevider from "./icons/FooterSectionDevider";
import CameraPosition from "./CameraPosition";

export default function Experience() {
    return (
        <>
            <Canvas
                flat
                dpr={[1,2]}
                className='main-canvas scroll-enabled'
                camera={{ position: [0, 0, 2] }}
            >
                <Lights/>
                <RotatePlateHandler/>
                <FooterSectionDevider/>
                <CameraPosition/>
            </Canvas>
            <Footer/>
        </>
    )
}