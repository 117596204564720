import { Html } from "@react-three/drei"
import PhoneRinging from "./icons/PhoneRinging"

export default function ProductInfo({ activeProduct }) {

    const thousandSeparatorRegEx = /\B(?=(\d{3})+(?!\d))/g
    const regularPrice = activeProduct.price.toString().replace(thousandSeparatorRegEx, '.')
    const integralPrice = (activeProduct.price + 200).toString().replace(thousandSeparatorRegEx, '.')

    return (
        <Html className='product-info'>
            <div className='product-info__name'>{activeProduct.name}</div>
            <div className='product-info__price'>{regularPrice} din/kg</div>
            <div className='product-info__name--integral'>Integralne</div>
            <div className='product-info__price--integral'>{integralPrice} din/kg</div>
            <div className='product-info__phone-number'>
                <PhoneRinging/> <a href='tel:+381653479070'>+381653479070</a>
            </div>
        </Html>
    )
}